import React from 'react'
import JoinUs from '../components/JoinUs'

export default function PostOpportunities() {
  return <>
    <br></br>
    <br></br>
    <br></br>
    <JoinUs />
  </>
}